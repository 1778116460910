export default [
    {
        path: "/mineSetting",
        name: "mineSetting",
        component: () => import("@/views/mine/mineSetting/index.vue"),
        meta: { title: '设置', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/mineAccount",
        name: "mineAccount",
        component: () => import("@/views/mine/account/index.vue"),
        meta: { title: '账号注销', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/mineRecords",
        name: "mineRecords",
        component: () => import("@/views/mine/records/index.vue"),
        meta: { title: '申请记录', returnBtn: true, module: 'loanAuth' }
    },
]