export default [
    {
        path: "/refundCard",
        name: "refundCard",
        component: () => import("@/views/refund/bankCard/index.vue"),
        meta: { title: '更换银行卡', returnBtn: true, module: 'loanAuth', keepAlive: true }
    },
    {
        path: "/refundSchedule",
        name: "refundSchedule",
        component: () => import("@/views/refund/schedule/index.vue"),
        meta: { title: '还款进度', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/auditing",
        name: "auditing",
        component: () => import("@/views/refund/earlySettle/auditing.vue"),
        meta: { title: '提前结清', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/earlySettle",
        name: "earlySettle",
        component: () => import("@/views/refund/earlySettle/index.vue"),
        meta: { title: '提前结清', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/manageList",
        name: "manageList",
        component: () => import("@/views/refund/manageList/index.vue"),
        meta: { title: '贷款管理', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/loadManage",
        name: "loadManage",
        component: () => import("@/views/refund/manageList/load.vue"),
        meta: { title: '贷款信息', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/loadContract",
        name: "loadContract",
        component: () => import("@/views/refund/manageList/contract.vue"),
        meta: { title: '合同管理', returnBtn: true, module: 'loanAuth' }
    }
]