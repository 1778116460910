import { saveOrUpdateApi } from "@/http/api/interface";
import { useUserStore } from "@/store/modules/user";
import { useRoute } from 'vue-router';


export const saveOrUpdate = () => {
    const useUser = useUserStore();
    const { creditNo, debtNo, token } = useUser
    console.log(creditNo, 'asifasgdadsgfas')
    if (creditNo && debtNo && token) {
        const detailData = getOperatingDetail()
        saveOrUpdateApi(detailData)
    }
}

/**
 * 获取设备信息
 */
export const getOperatingDetail = () => {
    const route = useRoute();
    const useUser = useUserStore();
    const { creditNo, debtNo } = useUser
    return {
        osPlatformNo: getOsPlatformNo(),
        osPlatform: 'web',
        deviceType: 'web',
        currPgTitl: route ? route.meta.title : '',
        currPgUrl: window.location.href.split('?')[0],
        creditNo,
        debtNo
    };
}

export const getOsPlatformNo = () => {
    const { userAgent } = navigator;
    let version
    if (/Windows NT 10.0/.test(userAgent)) {
        version = "Windows 10";
    } else if (/Windows NT 6.2/.test(userAgent)) {
        version = "Windows 8";
    } else if (/Windows NT 6.1/.test(userAgent)) {
        version = "Windows 7";
    } else if (/Mac OS X ([._\d]+)/.test(userAgent)) {
        const match = userAgent.match(/Mac OS X ([._\d]+)/)
        const result = match ? match[1] : '未知版本';
        version = "Mac OS X " + result;
    } else if (/Android ([._\d]+)/.test(userAgent)) {
        const match = userAgent.match(/Android ([._\d]+)/)
        const result = match ? match[1] : '未知版本';
        version = "Android " + result
    } else if (/iPhone OS ([._\d]+)/.test(userAgent)) {
        const match = userAgent.match(/iPhone OS ([._\d]+)/)
        const result = match ? match[1] : '未知版本';
        version = "iOS " + result.replace(/_/g, '.');
    }
    return version;
}