import { defineStore } from "pinia";
export const useLoanAuthStore = defineStore('loanAuth', () => {
  const loanAuthStatus = ref({}); // 页面需要展示元素
  const thirdCode = ref(''); // 三方认证方法
  const preSetps = ref({}); // 前置审核头部数据
  const jumpMethod = ref(false); // 跳转方式
  const authGroup = ref(''); // 当前认证节点
  const preAmount = ref(''); // 预审金额选择
  const setThirdCode = data => {
    thirdCode.value = data;
  };
  const setLoanAuthStatus = data => {
    loanAuthStatus.value = data;
  };
  const setPreSetps = data => {
    preSetps.value = data;
  };
  const setJumpMethod = data => {
    jumpMethod.value = data;
  };
  const setGroupStatus = data => {
    authGroup.value = data;
  };
  const setPreAmount = data => {
    preAmount.value = data;
  };
  /**
   * 重置数据
   */
  const resetState = () => {
    loanAuthStatus.value = {};
    thirdCode.value = '';
    preSetps.value = {};
    jumpMethod.value = false;
    authGroup.value = '';
    preAmount.value = '';
  };
  return {
    thirdCode,
    setThirdCode,
    loanAuthStatus,
    setLoanAuthStatus,
    preSetps,
    setPreSetps,
    jumpMethod,
    setJumpMethod,
    authGroup,
    setGroupStatus,
    preAmount,
    setPreAmount,
    resetState
  };
}, {
  persist: true
});