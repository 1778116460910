export default [
    {
        path: "/identity",
        name: "identity",
        component: () => import("@/views/loanAuth/preAudit/identity/index.vue"),
        meta: { title: '身份认证', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/authorizationIdentity",
        name: "authorizationIdentity",
        component: () => import("@/views/loanAuth/preAudit/identity/index.vue"),
        meta: { title: '身份认证', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/identityLoading",
        name: "identityLoading",
        component: () => import("@/views/loanAuth/preAudit/identity/loading.vue"),
        meta: { title: '身份认证', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/driver",
        name: "driver",
        component: () => import("@/views/loanAuth/preAudit/driver/index.vue"),
        meta: { title: '车辆信息', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/driverLoading",
        name: "driverLoading",
        component: () => import("@/views/loanAuth/preAudit/driver/loading.vue"),
        meta: { title: '车来呗', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: '/personSuccess',
        component: () => import('@/views/loanAuth/success/loading.vue'),
        name: 'personSuccess',
        meta: { title: '车来呗', returnBtn: true }
    },
    {
        path: "/preAmount",
        name: "preAmount",
        component: () => import("@/views/loanAuth/beforeAudit/preAmount/index.vue"),
        meta: { title: '预审额度', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/bankCard",
        name: "bankCard",
        component: () => import("@/views/loanAuth/beforeAudit/bankCard/index.vue"),
        meta: { title: '银行卡信息', returnBtn: true, module: 'loanAuth', keepAlive: true }
    },
    {
        path: "/baseInfo",
        name: "baseInfo",
        component: () => import("@/views/loanAuth/beforeAudit/baseInfo/index.vue"),
        meta: { title: '基本信息', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/workInfo",
        name: "workInfo",
        component: () => import("@/views/loanAuth/beforeAudit/workInfo/index.vue"),
        meta: { title: '工作信息', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/proofOfIncome",
        name: "proofOfIncome",
        component: () => import("@/views/loanAuth/beforeAudit/proofOfIncome/index.vue"),
        meta: { title: '流水证明', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/registerCard",
        name: "registerCard",
        component: () => import("@/views/loanAuth/beforeAudit/registerCard/index.vue"),
        meta: { title: '登记证书', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: '/signing',
        component: () => import("@/views/loanAuth/beforeAudit/signing/index.vue"),
        name: 'signing',
        meta: { title: '签约授权', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: '/orderLoading',
        component: () => import("@/views/loanAuth/beforeAudit/signing/orderLoading.vue"),
        name: 'orderLoading',
        meta: { title: '签约授权', returnBtn: false, module: 'loanAuth' }
    },
    {
        path: '/signingProtocol',
        component: () => import("@/views/loanAuth/beforeAudit/signing/protocol.vue"),
        name: 'signingProtocol',
        meta: { title: '车来呗', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: '/signingLoading',
        component: () => import("@/views/loanAuth/beforeAudit/signing/loading.vue"),
        name: 'signingLoading',
        meta: { title: '车来呗', returnBtn: false, noAuthority: true }
    },
    {
        path: '/signingHaoYunLoading',
        component: () => import("@/views/loanAuth/beforeAudit/signing/haoyunLoading.vue"),
        name: 'signingHaoYunLoading',
        meta: { title: '车来呗', returnBtn: false, noAuthority: true }
    },
    {
        path: '/personRecordingAgain',
        component: () => import("@/views/loanAuth/recordingAgain/index.vue"),
        name: 'personRecordingAgain',
        meta: { title: '信息补录', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/companyinfo",
        name: "companyinfo",
        component: () => import("@/views/loanAuth/afterAudit/appendixInfo/companyinfo.vue"),
        meta: { title: '营业执照', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/reviewPage",
        name: "reviewPage",
        component: () => import("@/views/loanAuth/afterAudit/reviewPage/index.vue"),
        meta: { title: '额度审批', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/driverCardInfo",
        name: "driverCardInfo",
        component: () => import("@/views/loanAuth/afterAudit/driverCardInfo/index.vue"),
        meta: { title: '车辆补充信息', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/appendixInfo",
        name: "appendixInfo",
        component: () => import("@/views/loanAuth/afterAudit/appendixInfo/index.vue"),
        meta: { title: '证明材料', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/appendixInfoUpload",
        name: "appendixInfoUpload",
        component: () => import("@/views/loanAuth/afterAudit/appendixInfo/upload.vue"),
        meta: { title: '证明材料', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/spouseBasic",
        name: "spouseBasic",
        component: () => import("@/views/loanAuth/afterAudit/spouseBasic/index.vue"),
        meta: { title: '配偶基本信息', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/spouseWork",
        name: "spouseWork",
        component: () => import("@/views/loanAuth/afterAudit/spouseWork/index.vue"),
        meta: { title: '配偶工作信息', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/basicPage",
        name: "basicPage",
        component: () => import("@/views/loanAuth/afterAudit/basicPage/index.vue"),
        meta: { title: '基本信息', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/personContract",
        name: "personContract",
        component: () => import("@/views/loanAuth/contractSigning/index.vue"),
        meta: { title: '确认拿钱', returnBtn: true }
    },
    {
        path: "/contractSigning",
        name: "contractSigning",
        component: () => import("@/views/loanAuth/contractSigning/signing.vue"),
        meta: { title: '签署合同', returnBtn: true, keepAlive: true }
    },
    {
        path: '/personHaoyunLoading',
        name: 'personHaoyunLoading',
        component: () => import("@/views/loanAuth/contractSigning/haoyunLoading.vue"),
        meta: { title: '合同签署加载', returnBtn: false, noAuthority: true }
    },
    {
        path: '/contractFinish',
        name: 'contractFinish',
        component: () => import("@/views/loanAuth/contractSigning/finish.vue"),
        meta: { title: '合同签署-完成', returnBtn: true }
    },
    {
        path: '/dealingBreachProtocol',
        name: 'dealingBreachProtocol',
        component: () => import("@/views/loanAuth/dealingBreach/index.vue"),
        meta: { title: '三方协议', returnBtn: true, keepAlive: true }
    },
    {
        path: '/rentalContractSigningResult',
        component: () => import("@/views/fddResult/index.vue"),
        name: 'rentalContractSigningResult',
        meta: { title: '合同签署', returnBtn: false, noAuthority: true }
    },
    {
        path: '/dealingBreachAuctionAuthorization',
        name: 'dealingBreachAuctionAuthorization',
        component: () => import("@/views/loanAuth/dealingBreach/auctionAuthorization.vue"),
        meta: { title: '三方协议', returnBtn: true }
    },
    {
        path: '/dealingBreachAuctionAuthorizationOk',
        name: 'dealingBreachAuctionAuthorizationOk',
        component: () => import("@/views/loanAuth/dealingBreach//auctionAuthorizationOk.vue"),
        meta: { title: '三方协议', returnBtn: true }
    },
    {
        path: '/feePayment',
        name: 'feePayment',
        component: () => import("@/views/loanAuth/feePayment/index.vue"),
        meta: { title: '费用支付', returnBtn: true }
    },
    {
        path: '/feePaymentDone',
        name: 'feePaymentDone',
        component: () => import("@/views/loanAuth/feePayment/done.vue"),
        meta: { title: '费用支付', returnBtn: true }
    },
    {
        path: '/feePaymentCard',
        name: 'feePaymentCard',
        component: () => import("@/views/loanAuth/feePayment/card.vue"),
        meta: { title: '费用支付', returnBtn: true, keepAlive: true, }
    },
    {
        path: '/businessAdd',
        component: () => import('@/views/loanAuth/addBusiness/index.vue'),
        name: 'businessAdd',
        meta: { title: '车务信息', returnBtn: true }
    },
    {
        path: '/loanSupplement',
        component: () => import('@/views/loanAuth/loanSupplement/index.vue'),
        name: 'loanSupplement',
        meta: { title: '补充材料', returnBtn: true }
    },
    {
        path: '/loanSupplementUpload',
        component: () => import('@/views/loanAuth/loanSupplement/upload.vue'),
        name: 'loanSupplementUpload',
        meta: { title: '补充材料', returnBtn: true }
    },
    {
        path: '/loanError',
        component: () => import('@/views/loanAuth/error/index.vue'),
        name: 'loanError',
        meta: { title: '车妈妈', returnBtn: true }
    },
]