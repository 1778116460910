export default {
    /**
     * 添加
     * @param {*} key 键名
     * @param {*} params 值
     */
    addStorage(key: string, params: any) {
        if (typeof (params) == "undefined") {
            params = null
        }
        localStorage.setItem(key, JSON.stringify(params))
    },
    /**
     * 取数据
     * @param {*} key 键名
     * @returns
     */
    getStorage(key: string) {
        if (localStorage.getItem(key)) {
            return JSON.parse(localStorage.getItem(key) || '')
        } else {
            return ''
        }
    },

    /**
     * 删除数据
     * @param {*} key 键名
     */
    delStorage(key: string) {
        localStorage.removeItem(key)
    },
    /**
     * 清空数据
     */
    delAllStorage() {
        localStorage.clear()
    },

}
