import fetch from '../interface';


/**
 * 省份、市区、县区区域树列表查询
 */

export function getRegionCountyTreeApi() {
    return fetch.request({
        url: '/interface_v/api/loan/app/region/getRegionCountyTree',
        method: 'get',
    });
}

/**
 *   获取短信验证码
 */
export function getSmsCodeApi(data: object) {
    return fetch.request({
        url: '/interface_v/api/loan/user/getSmsCode',
        method: 'post',
        data
    });
}

/**
 * 获取OSS STS临时认证
 */

export function getStsTokenApi(params: object) {
    return fetch.request({
        url: `/interface_v/api/loan/system/sts/getStsToken`,
        method: 'get',
        params
    });
}

/**
 * 保存审核放款补充资料
 */
export function frontSaveOrUpdateApi(data: object) {
    return fetch.request({
        url: `/interface_v/api/loan/audit/loanSupplementData/frontSaveOrUpdate`,
        method: 'post',
        data
    });
}

/*
 * 查询用户银行卡
*/
export function getByUserIdAndDebtNoDriverApi(params: object) {
    return fetch.request({
        url: '/interface_v/api/loan/credit/driver/getByUserIdAndDebtNo',
        method: 'get',
        params
    })
}

/*
 * 获取图形验证码
*/
export function getImgCodeApi() {
    return fetch.request({
        url: '/interface_v/api/loan/user/getImgCode',
        method: 'get',
    })
}

/**
 *   短信验证码登录
 */
export function signinSmsApi(data: object) {
    return fetch.request({
        url: '/interface_v/api/loan/user/signinSms',
        method: 'post',
        data: data
    });
}

/**
 * 获取产品类型列表（含业务单信息）
 */
export function productTypeListWithSalesOrderApi(params: object) {
    return fetch.request({
        url: `/interface_v/api/loan/debt/salesOrder/inter/productTypeListWithSalesOrder`,
        method: 'post',
        params
    })
}

/**
 * 获取单子可以申请的其他产品类型列表
 */
export function moreProductTypeListApi(params: object) {
    return fetch.request({
        url: `/interface_v/api/loan/debt/salesOrder/inter/moreProductTypeList`,
        method: 'post',
        params
    })
}

/**
 *   创建业务单
 */
export function createDebtApi(params: object) {
    return fetch.request({
        url: `/interface_v/api/loan/debt/salesOrder/inter/create`,
        method: 'post',
        params
    })
}

/**
 * 查询认证分组状态
 */
export function loanAllAuthGroupStatusApi(data: object) {
    return fetch.request({
        url: `/interface_v/api/loan/credit/creditAuth/loanAllAuthGroupStatus`,
        method: 'post',
        data
    });
}

/**
 * 获取所有认证项状态
*/
export function loanAuthStatusApi(data: object) {
    return fetch.request({
        url: '/interface_v/api/loan/credit/creditAuth/loanAuthStatus',
        method: 'post',
        data: data
    });
}

/**
 * 是否可以更改卡
 */
export function canChangeCardApi(data: object) {
    return fetch.request({
        url: `/interface_v/api/loan/pay/bankCard/canChangeCard`,
        method: 'post',
        data
    });
}

/**
 * 用户业务单列表信息
 */
export function userSalesOrderListApi(data: object) {
    return fetch.request({
        url: `/interface_v/api/loan/debt/salesOrder/inter/userSalesOrderList`,
        method: 'post',
        data
    });
}

/**
 *   获取工单列表(用户端)，还款
 */
export function queryUserDebtListApi() {
    return fetch.request({
        url: '/interface_v/api/loan/debt/userDebtList',
        method: 'get',
    });
}

/**
 * 查询工单还款银行卡
 */
export function getDebtRepaymentBankCardApi(params: object) {
    return fetch.request({
        url: '/interface_v/api/loan/pay/bankCard/getDebtRepaymentBankCard',
        method: 'get',
        params
    });
}

/**
 * 更改代扣卡
 */
export function changeWithholdCardApi(data: object) {
    return fetch.request({
        url: '/interface_v/api/loan/pay/bankCard/changeWithholdCard',
        method: 'post',
        data
    });
}

/**
 *   获取还款进度
 */
export function queryLoanProgress(params: object) {
    return fetch.request({
        url: '/interface_v/api/loan/pay/repayment/inter/loanProgress',
        method: 'get',
        params
    });
}

/**
 * 提前结清, 查询
 */
export function queryApplyApi(params: object) {
    return fetch.request({
        url: '/interface_v/api/loan/pay/offlineRepayment/queryApply',
        method: 'post',
        params
    });
}

/**
 * 查询 提前结清
 */
export function applyApi(params: object) {
    return fetch.request({
        url: '/interface_v/api/loan/pay/offlineRepayment/apply',
        method: 'post',
        params
    });
}


/**
 * 提前结清，上传凭证
 */
export function uploadVoucherApi(data: object) {
    return fetch.request({
        url: '/interface_v/api/loan/pay/offlineRepayment/uploadVoucher',
        method: 'post',
        data
    });
}

/**
 *   获取贷款详情
 */
export function queryLoanDetailApi(params: object) {
    return fetch.request({
        url: '/interface_v/api/loan/pay/repayment/inter/loanDetail',
        method: 'get',
        params
    });
}

/*
 * 合同管理列表
*/
export function getContractApi(params: object) {
    return fetch.request({
        url: '/interface_v/api/loan/debt/contract/getContract',
        method: 'get',
        params
    })
}

/*
 * 录入客户信息
*/
export function addUserApi(data: object) {
    return fetch.request({
        url: '/interface_v/api/loan/user/addUser',
        method: 'post',
        data
    })
}

/**
 * 试算每期应还金额
 */
export function preformApi(data: object) {
    return fetch.request({
        url: `/interface_v/api/loan/pay/repayment/inter/preform`,
        method: 'post',
        data: data
    })
}


/**
 * 保存授信单埋点数据
 */
export function saveOrUpdateApi(data: object) {
    return fetch.request({
        url: `/interface_v/api/loan/audit/buriedPoint/saveOrUpdate`,
        method: 'post',
        data
    })
}

/**
 * 绑卡协议
 */
export function bindCardProtocolApi(data: object) {
    return fetch.request({
        url: `/interface_v/api/loan/pay/bankCard/bindCardProtocol`,
        method: 'post',
        data
    })
}

/**
 * 绑卡
 */
export function bindCardApi(data: object) {
    return fetch.request({
        url: '/interface_v/api/loan/pay/bankCard/bindCard',
        method: 'post',
        data
    });
}

/**
 * 获取当前用户最新在途业务单工单信息
 */
export function findNewestInLoanOrderApi() {
    return fetch.request({
        url: '/interface_v/api/loan/debt/salesOrder/inter/findNewestInLoanOrder',
        method: 'post',
    });
}


