import { showLoadingToast } from 'vant';

export function isMobile() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
        return true // 手机端
    } else {
        return false // alert('PC端')
    }
}

/**
 * 设置输入框只能输入数字字母
 * @param data 需要处理的数据
 * @returns 返回出口之后的数据
 */
export function alphanumericFu(data: any) {
    // 移除非数字和字母以外的字符
    return data.replace(/[^0-9A-Za-z]/g, '');
}
/**
 * 设置密码框
 * @param data 需要处理的数据
 * @returns 返回出口之后的数据
 */
export function passwordFu(data: any) {
    return data.replace(/[\u4E00-\u9FA5]/g, '')
}
/**
 * 设置只能输入手机号
 */
export function phoneFu(data: any) {
    if (data[0] != 1) {
        return ''
    }
    if (data.length == 1) {
        return data = data.replace(/[^1]/g, '');
    } else {
        return data = data.replace(/\D/g, '');
    }
}

/**
 * 验证校验
 */
export function smsCodeInput(data: any) {
    return data = data.replace(/[^\d]/g, '');
}

/**
 * 设置全局loading动画
 */

// 全局加载动画

export const loadingFu = (text?: string) => {
    const loading = showLoadingToast({
        message: text || '加载中...',
        duration: 0, // 持续展示 toast
        forbidClick: true,
        className: 'z_index'
    });
    return loading
}

/**
 * 日期格式转换
*/

export const dateStrToDateFormat = (str: string, format = 'yyyy-mm-dd') => {
    if (!str) return ''

    const date = new Date(str)
    const year = date.getFullYear()
    let month = (date.getMonth() + 1) + ''
    let day = date.getDate() + ''
    let hours = date.getHours() + ''
    let minutes = date.getMinutes() + ''
    let seconds = date.getSeconds() + ''
    month = month.padStart(2, '0')
    day = day.padStart(2, '0')
    hours = hours.padStart(2, '0')
    minutes = minutes.padStart(2, '0')
    seconds = seconds.padStart(2, '0')

    if (format == 'yyyy-mm-dd') {
        return `${year}-${month}-${day}`
    } else {
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    }
}

export function getDatetime() {
    const now = new Date()
    const year = now.getFullYear()
    const month = now.getMonth() + 1
    const day = now.getDate()
    const LastWeekDate = new Date(now.getTime() - 7 * 24 * 3600 * 1000)
    const lastYear = LastWeekDate.getFullYear()
    const lastMonth = LastWeekDate.getMonth() + 1
    const LastDay = LastWeekDate.getDate()
    //现在的时间
    let clock = `${year}年`
    if (month < 10) clock += "0"
    clock += `${month}月`
    if (day < 10) clock += "0"
    clock += `${day}日`
    //一周前的时间
    let lastClock = `${lastYear}年`
    if (lastMonth < 10) lastClock += "0"
    lastClock += `${lastMonth}月`
    if (LastDay < 10) lastClock += "0"
    lastClock += `${LastDay}日`
    // 现在的时间
    let clockShow = `${year},`
    if (month < 10) clockShow += "0"
    clockShow += `${month},`
    if (day < 10) clockShow += "0"
    clockShow += `${day}`
    //一周前的时间
    let lastClockShow = `${lastYear},`
    if (lastMonth < 10) lastClockShow += "0"
    lastClockShow += `${lastMonth},`
    if (LastDay < 10) lastClockShow += "0"
    lastClockShow += `${LastDay}`
    return { clock, clockShow, lastClock, lastClockShow }
}


// JS实现日期比较大小(JavaScript)
export function compareDate(date1: string | number | Date, date2: string | number | Date) {
    const oDate1 = new Date(date1);
    const oDate2 = new Date(date2);
    if (oDate1.getTime() > oDate2.getTime()) {
        // console.log('第一个大');
        return true;
    } else if (oDate1.getTime() < oDate2.getTime()) {
        // console.log('第二个大');
        return false;
    } else if (oDate1.getTime() == oDate2.getTime()) {
        // console.log('一样大');
        return true;
    }
}

/**
 * area数据格式转换
*/
export function areasMap(arr: any[]) {
    arr.forEach(item => {
        item.text = item.name
        item.value = item.code
        if (item.children && item.children.length) {
            areasMap(item.children)
        }
    })
    return arr
}


/**
 * 加密手机号
 */
export function processPhoneNumber(phoneNumber: string) {
    if (!phoneNumber) {
        return phoneNumber
    }
    // 正则表达式匹配已处理的号码格式（如180****4250）
    const maskedPattern = /^\d{3}\*{4}\d{4}$/;

    // 如果号码已经是处理过的格式，则不做任何处理
    if (maskedPattern.test(phoneNumber)) {
        return phoneNumber;
    }

    // 如果号码未处理，则将中间四位数字替换为星号
    return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');
}

/**
 * 获取设备信息
 */
// export const getOperatingDetail = () => {
// const { creditNo, debtNo } = useUser
// console.log(creditNo, debtNo, router);

// return {
//     osPlatformNo: getOsPlatformNo(),
//     osPlatform: 'web',
//     deviceType: 'web',
//     currPgTitl: router.history.current.meta.title,
//     currPgUrl: window.location.href.split('?')[0],
//     creditNo,
//     debtNo
// };
// }

// export const getOsPlatformNo = () => {
//     const { userAgent } = navigator;
//     let version
//     if (/Windows NT 10.0/.test(userAgent)) {
//         version = "Windows 10";
//     } else if (/Windows NT 6.2/.test(userAgent)) {
//         version = "Windows 8";
//     } else if (/Windows NT 6.1/.test(userAgent)) {
//         version = "Windows 7";
//     } else if (/Mac OS X ([\.\_\d]+)/.test(userAgent)) {
//         version = "Mac OS X " + userAgent.match(/Mac OS X ([\.\_\d]+)/)[1];
//     } else if (/Android ([\.\_\d]+)/.test(userAgent)) {
//         version = "Android " + userAgent.match(/Android ([\.\_\d]+)/)[1];
//     } else if (/iPhone OS ([\.\_\d]+)/.test(userAgent)) {
//         version = "iOS " + userAgent.match(/iPhone OS ([\.\_\d]+)/)[1].replace(/_/g, '.');
//     }
//     return version;
// }