export default [
    {
        path: '/cardMonthly',
        component: () => import("@/views/monthlyRent/card/index.vue"),
        name: 'cardMonthly',
        meta: { title: '银行卡信息', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: '/contractMonthly',
        component: () => import("@/views/monthlyRent/contractSigning/index.vue"),
        name: 'contractMonthly',
        meta: { title: '租赁合同', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: '/contractProtocolMonthly',
        component: () => import("@/views/monthlyRent/contractSigning/protocol.vue"),
        name: 'contractProtocolMonthly',
        meta: { title: '租赁合同', returnBtn: true, keepAlive: true, module: 'loanAuth' }
    },
    {
        path: "/feePaymentMonthly",
        name: "feePaymentMonthly",
        component: () => import("@/views/monthlyRent/feePayment/index.vue"),
        meta: { title: '费用支付', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/feePaymentAuto",
        name: "feePaymentAuto",
        component: () => import("@/views/monthlyRent/feePayment/auto.vue"),
        meta: { title: '费用支付', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/feePaymentDoneMonthly",
        name: "feePaymentDoneMonthly",
        component: () => import("@/views/monthlyRent/feePayment/done.vue"),
        meta: { title: '费用支付', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/leaseDetail",
        name: "leaseDetail",
        component: () => import("@/views/monthlyRent/lease/index.vue"),
        meta: { title: '租赁管理', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/leasePayment",
        name: "leasePayment",
        component: () => import("@/views/monthlyRent/lease/payment.vue"),
        meta: { title: '租金支付', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/leaseResult",
        name: "leaseResult",
        component: () => import("@/views/monthlyRent/lease/result.vue"),
        meta: { title: '', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/leaseContract",
        name: "leaseContract",
        component: () => import("@/views/monthlyRent/lease/contract.vue"),
        meta: { title: '续租确认', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/leaseProtocol",
        name: "leaseProtocol",
        component: () => import("@/views/monthlyRent/lease/protocol.vue"),
        meta: { title: '合同签署', returnBtn: true, keepAlive: true, module: 'loanAuth' }
    },
    {
        path: "/leaseDepositDetails",
        name: "leaseDepositDetails",
        component: () => import("@/views/monthlyRent/lease/depositDetails.vue"),
        meta: { title: '押金明细', returnBtn: true, module: 'loanAuth' }
    },
    {
        path: "/buyBack",
        name: "buyBack",
        component: () => import("@/views/monthlyRent/buyBack/index.vue"),
        meta: { title: '车辆回购', returnBtn: true, module: 'loanAuth' }
    }
]