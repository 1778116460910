import _defineProperty from "/usr/share/we-saas/h5-car-customer/deploy/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { defineStore } from "pinia";
import { useLoanAuthStore } from "./loanAuth";
class leaseContractParams {
  constructor() {
    _defineProperty(this, "repayType", '');
    _defineProperty(this, "termTypeCode", '');
    _defineProperty(this, "code", '');
    _defineProperty(this, "name", '');
  }
}
export const useUserStore = defineStore('use', () => {
  const useLoanAuth = useLoanAuthStore();
  const token = ref('');
  const userId = ref('');
  const creditNo = ref('');
  const debtNo = ref('');
  const salesOrderNo = ref('');
  const leaseNo = ref('');
  const planNo = ref(''); // 当前租赁期数编号
  const phoneNo = ref('');
  const orderType = ref(''); //  判断是月月租还是车贷
  const leaseContractData = ref(new leaseContractParams()); // 续租期数数据
  const leaseState = ref(false); // 判断是续租还是首租  false 首租 true 续租
  const setTokenFu = data => {
    token.value = data;
  };
  const setUserIdFu = data => {
    userId.value = data;
  };
  const setDebtNo = data => {
    debtNo.value = data;
  };
  const setLeaseNo = data => {
    leaseNo.value = data;
  };
  const setPlanNo = data => {
    planNo.value = data;
  };
  const setPhoneNo = data => {
    phoneNo.value = data;
  };
  const setOrderTypeFu = data => {
    orderType.value = data;
  };
  const setCreditNoTxNoFu = data => {
    creditNo.value = data.creditNo;
    debtNo.value = data.debtNo;
    salesOrderNo.value = data.salesOrderNo;
  };
  const setLeaseContractDataFu = data => {
    leaseContractData.value = data;
  };
  const setLeaseState = data => {
    leaseState.value = data;
  };
  /**
   * 重置数据
   */
  const resetState = () => {
    token.value = '';
    userId.value = '';
    creditNo.value = '';
    debtNo.value = '';
    salesOrderNo.value = '';
    leaseNo.value = '';
    planNo.value = '';
    phoneNo.value = '';
    orderType.value = '';
    leaseState.value = false;
    leaseContractData.value = new leaseContractParams();
    useLoanAuth.resetState();
  };
  return {
    token,
    userId,
    creditNo,
    debtNo,
    salesOrderNo,
    leaseNo,
    planNo,
    phoneNo,
    orderType,
    leaseContractData,
    leaseState,
    setTokenFu,
    setUserIdFu,
    setCreditNoTxNoFu,
    setLeaseNo,
    setPlanNo,
    setPhoneNo,
    setOrderTypeFu,
    setDebtNo,
    setLeaseContractDataFu,
    setLeaseState,
    resetState
  };
}, {
  persist: true
});