import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { saveOrUpdate } from "@/minis";
import loanAuth from "./loanAuth";
import mine from "./mine";
import refund from "./refund";
import { useUserStore } from "@/store/modules/user";
import monthlyRent from "./monthlyRent"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/login",
        name: "login",
        component: () => import("@/views/login/index.vue"),
        meta: { title: '登陆', returnBtn: false, keepAlive: true }
    },
    {
        path: "/apply",
        name: "apply",
        component: () => import("@/views/apply/index.vue"),
        meta: { title: '车来呗', returnBtn: false, keepAlive: true, noAuthority: true }
    },
    {
        path: "/loading",
        name: "loading",
        component: () => import("@/views/loading/index.vue"),
        meta: { title: '加载页面', returnBtn: false }
    },
    {
        path: '/',
        component: () => import('@/views/layout/index.vue'),
        redirect: '/home',
        children: [
            {
                path: '/home',
                component: () => import('@/views/layout/home/index.vue'),
                name: 'home',
                meta: { title: '车来呗', returnBtn: false }
            },
            {
                path: '/mine',
                component: () => import('@/views/layout/mine/index.vue'),
                name: 'mine',
                meta: { title: '我的', returnBtn: false }
            },
            {
                path: '/refund',
                component: () => import('@/views/layout/refund/index.vue'),
                name: 'refund',
                meta: { title: '车抵贷', returnBtn: false }
            },
        ],
    },
    {
        path: "/protocolAll",
        name: "protocolAll",
        component: () => import("@/views/protocolAll/index.vue"),
        meta: { title: '协议', returnBtn: true, module: 'loanAuth', noAuthority: true }
    },
    {
        path: "/readProtocol",
        name: "readProtocol",
        component: () => import("@/views/protocolAll/readProtocol.vue"),
        meta: { title: '协议', returnBtn: true, module: 'loanAuth', noAuthority: true }
    },
    {
        path: '/calculate',
        name: 'calculate',
        component: () => import("@/views/calculate/index.vue"),
        meta: { title: '车贷计算器', returnBtn: true }
    },
    {
        path: '/interactive',
        name: 'interactive',
        component: () => import("@/views/interactive/index.vue"),
        meta: { title: '', returnBtn: true, noAuthority: true }
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes: [...routes, ...loanAuth, ...mine, ...refund, ...monthlyRent]
});

/**
 * 路由拦截
 *
 */

router.beforeEach((to, from, next) => {
    const useUser = useUserStore()
    const token = useUser.token
    const noAuthority = to.meta?.noAuthority
    if (noAuthority) {
        next()
        return
    }
    if (to.path === '/interactive') {
        next()
        return
    }
    if (to.path === '/home' && to.query.token) {
        useUser.setTokenFu(to.query.token as string)
        useUser.setUserIdFu(to.query.userId as string)
        next()
        return
    }
    if (to.path === '/loading' && to.query.token) {
        useUser.setTokenFu(to.query.token as string)
        useUser.setUserIdFu(to.query.userId as string)
        next()
        return
    }
    // 判断为业务模块的
    if (token) {
        if (to.matched.length === 0) {
            next('/home')
        } else if (to.path == '/login') {
            next('/home')
        } else {
            saveOrUpdate()
            next()
        }
    } else {
        if (to.path == '/login') {
            next()
        } else {
            next('/login')
        }
    }
})

export default router;
